import React from 'react'
import SupportDocsRenderers from './SupportDocsRenderers'

const ViewSupportDocs = (props) => {
    console.log('{ViewSupportDocs}')
    console.log('{ViewSupportDocs}: props=',props)
    return (
        <>
        {
            props.supportDocs.map((doc,i) => {
                const View = SupportDocsRenderers[doc.type]?? <></>
                return <View key={i} {...doc} />
            })
        }
        </>
    )
}

export default ViewSupportDocs