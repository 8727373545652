import React, { useEffect, useRef, useState } from 'react';
import Utils from '../../lib/Utils';
import Assets from '../../lib/Assets';
import StateUpdators from '../../lib/StateUpdators';
import { useParams } from 'react-router-dom';
import NavMenu from './NavMenu';

/**
 * The account header
 * @returns 
 */
const AccountHeader = (props) => {
    console.log('{AccountHeader}');
    const [user, setUser] = useState({
        firstname: 'Guest',
        lastname: 'User',
        username: 'guest',
        iconAvailable: false,
        icon: Assets.icons.avatarPlaceHolder,
        iconTitle: 'User Icon Not Available',
        userType: 'Student'
    });
    StateUpdators.setUser = setUser;
    const token = useParams().token;
    const fetched = useRef(null);
    useEffect(() => {
        if(!fetched.current) {
            Utils.fetchUserDetails({...props})
            fetched.current = true;
        }
    });
    return (
        <>
        <div className='nav-hdr-nghfgfd'>
            <div className='left-jfhfh-jhfhf'>
                <ul>
                    <li className='ptr' title='Home' onClick={() => {
                        Utils.addAnalytics({
                            page: 'AccountHeader',
                            url: window.location.href,
                            title: 'NS.AccountHeader',
                            action: 'ClickHomeLink'
                        });
                        Utils.accountHome();
                    }}>
                        <img alt='Home' className='nfhdh-nv-icon' src={Assets.icons.home} />
                        <span className='hdhdg-tct'>Home</span>
                    </li>
                    {
                        props.showLessonsLink &&
                        <li className='ptr' title='Lessons' onClick={() => {
                            Utils.addAnalytics({
                                page: 'AccountHeader',
                                url: window.location.href,
                                title: 'NS.AccountHeader',
                                action: 'ClickLessonsLink'
                            });
                            Utils.showLessonsNavMenu({token: JSON.parse(Utils.base64Decode(token))});
                        }}>
                            <img alt='Home' className='nfhdh-nv-icon' src={Assets.icons.lessons} />
                            <span className='hdhdg-tct'>Lessons</span>
                        </li>
                    }
                    {
                        props.showActivitiesLink &&
                        <li className='ptr' title='Lessons' onClick={() => {
                            Utils.addAnalytics({
                                page: 'AccountHeader',
                                url: window.location.href,
                                title: 'NS.AccountHeader',
                                action: 'ClickActivitiesLink'
                            });
                            Utils.showActivitiesMenu({token: JSON.parse(Utils.base64Decode(token))});
                        }}>
                            <img alt='Home' className='nfhdh-nv-icon' src={Assets.icons.activities} />
                            <span className='hdhdg-tct'>Activities</span>
                        </li>
                    }
                </ul>
            </div>
            <div className='right-jfhfh-jhfhf'>
                <ul>
                    <li className='ptr' title='Notifications' onClick={() => {
                        Utils.addAnalytics({
                            page: 'AccountHeader',
                            url: window.location.href,
                            title: 'NS.AccountHeader',
                            action: 'ClickNotificationsIcon'
                        });
                        Utils.showUserNotifications();
                    }}>
                        <img alt='Notifications' className='nfhdh-nv-icon' src={Assets.icons.notifications} />
                    </li>
                    <li className='ptr' title={user.firstname} onClick={(e) => {
                        Utils.addAnalytics({
                            page: 'AccountHeader',
                            url: window.location.href,
                            title: 'NS.AccountHeader',
                            action: 'ClickAvatarIcon'
                        });
                            Utils.handleMenuClick(e);
                        }}>
                        <img title={user.iconTitle} alt={user.iconTitle} className='nfhdh-nv-icon' src={user.icon} />
                        <span className='hdhdg-tct ksjsdhd-pep u-name-jmfhfh'>{user.firstname}</span>
                    </li>
                </ul>
            </div>
        </div>
        <NavMenu userType={user.userType} avatarIcon={user.iconAvailable?user.icon:false} avatarTitle={user.iconTitle} />
        </>
    )
}

export default AccountHeader;